import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import styled from "styled-components";

class CeremonyRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <RollWrapper className="inner">
        <h1>Ceremonies</h1>
        {posts &&
          posts.map(({ node: post }) => (
            <article key={post.id}>
              {post.frontmatter.featuredimage ? (
                <PreviewCompatibleImage
                  imageInfo={{
                    image: post.frontmatter.featuredimage,
                    alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                  }}
                />
              ) : null}

              <div>
                <h4>{post.frontmatter.title}</h4>
                <p>{post.frontmatter.description}</p>
                <Link to={post.fields.slug}>Read more</Link>
              </div>
            </article>
          ))}
      </RollWrapper>
    );
  }
}

const RollWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1fr);
  gap: 20px;
  position: relative;
  z-index: 20;
  max-width: calc(100%);

  article {
    display: flex;
    background-color: var(--white);
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);

    @media (max-width: 760px) {
      flex-direction: column;
      max-width: calc(100% - 40px);
    }

    h4 {
      margin-bottom: 10px;
    }

    h4,
    p {
      color: var(--dark);
    }

    .gatsby-image-wrapper {
      width: 200px;
      height: 100%;
      flex-shrink: 0;

      @media (max-width: 760px) {
        width: 100%;
        height: 100px;
        flex-shrink: 1;
      }
    }

    & > div {
      padding: 20px;
      display: flex;
      flex-direction: column;

      a {
        margin-top: auto;

        @media (max-width: 760px) {
          margin-top: 20px;
        }
      }
    }
  }
`;

CeremonyRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query CeremonyRollQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "ceremony-page" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                description
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <CeremonyRoll data={data} count={count} />}
  />
);
